import { toast } from 'react-toastify';
import { GET_ERRORS,  GET_SETTING, EDIT_SETTING, BEFORE_SETTING } from './Types';
import { emptyError } from '../../redux/shared/error/error.action';
import { ENV } from '../../config/config';

export const beforeSetting = () => {
    return {
        type: BEFORE_SETTING
    }
}


export const getSetting = () => dispatch => {
    
   
    dispatch(emptyError());
    let url = `${ENV.url}settings`;

    fetch(url, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'Authorization': `Bearer `+localStorage.getItem('auth'),
            'x-auth-token': ENV.x_auth_token,
            'x-auth-user': ENV.x_auth_user
        }
    }).then(res => res.json()).then(data => {
       
        if (data) {
          
            dispatch({
                type: GET_SETTING,
                payload: data
            })
        } else {
            toast.error(data.message)
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(error => {
        if (error.response && error.response.data) {
            const { data } = error.response
            if (data.message)
                toast.error(data.message)
        }
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
};

export const updateSetting = (body) => dispatch => {

    dispatch(emptyError());
    const url = `${ENV.url}update-setting`;

    fetch(url, {
        method: 'POST',
        headers: {
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'x-auth-user': ENV.x_auth_user
        },
        body
    }).then(res => res.json()).then(data => {
        if (data.success) {
            toast.success(data.message)
            dispatch({
                type: EDIT_SETTING,
                payload: data
            })
        } else {
            toast.error(data.data.error)
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(error => {
        if (error.response && error.response.data) {
            const { data } = error.response
            if (data.message)
                toast.error(data.message)
        }
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
};
