// ERRORS
export const EMPTY_ERRORS = 'EMPTY_ERRORS';
export const GET_ERRORS = 'GET_ERRORS';

// ROLE
export const GET_ROLES = 'GET_ROLES'
export const BEFORE_ROLE = 'BEFORE_ROLE'
export const DELETE_ROLE= 'DELETE_ROLE'
export const CREATE_ROLE = 'CREATE_ROLE'
export const GET_ROLE = 'GET_ROLE'
export const EDIT_ROLE = 'EDIT_ROLE'
export const GET_ALL_PERMISSIONS = 'GET_ALL_PERMISSIONS'
export const GET_ROLE_PERMISSIONS = 'GET_ROLE_PERMISSIONS'
export const GET_USER_PERMISSIONS = 'GET_USER_PERMISSIONS'

