import React from 'react';

// layouts
import Guest from './layouts/guest';
import Authenticate from './layouts/authenticate';


// components
const LoginPage = React.lazy(() => import('./views/Login/login'));
const RegisterPage = React.lazy(() => import('./views/Register/register'));
const ForgetPassword = React.lazy(() => import('./views/Password/forgot-password'));
const ChangePassword = React.lazy(() => import('./views/Password/change-password'));
const Dashboard = React.lazy(() => import('./views/Dashboard/dashboard'));
const Country = React.lazy(() => import('./views/Country/Country'));
const AddCountries = React.lazy(() => import('./views/Country/AddCountry'));
const EditCountry = React.lazy(() => import('./views/Country/EditCountry'));
const Store = React.lazy(() => import('./views/Store/Store'));
const AddStore = React.lazy(() => import('./views/Store/AddStore'));
const EditStore = React.lazy(() => import('./views/Store/EditStore'));
const State = React.lazy(() => import('./views/State/State'));
const AddState = React.lazy(() => import('./views/State/AddState'));
const EditState = React.lazy(() => import('./views/State/EditState'));
const Faq = React.lazy(() => import('./views/Faq/Faq'));
const AddFaq = React.lazy(() => import('./views/Faq/AddFaq'));
const EditFaq = React.lazy(() => import('./views/Faq/EditFaq'));
const City = React.lazy(() => import('./views/City/City'));
const AddCity = React.lazy(() => import('./views/City/AddCity'));
const EditCity = React.lazy(() => import('./views/City/EditCity'));
const KviOverview = React.lazy(() => import('./components/main-pages/kvi-overview/Kvi-Overview'));
const IncentivePage = React.lazy(() => import('./components/main-pages/incentive/incentive'));
const Wizard = React.lazy(() => import('./components/extra-pages/wizard/wizard'));
const RolesPermissions = React.lazy(() => import('./views/RolePermission/RolePermission'));
const Users = React.lazy(() => import('./views/User/Users'));
const AddUser = React.lazy(() => import('./views/User/AddUser'));
const EditUser = React.lazy(() => import('./views/User/EditUser'));
const ReportsPage = React.lazy(() => import('./components/extra-pages/reports-page/ReportsPage'));
const AddRoles = React.lazy(() => import('./views/RolePermission/AddRoles'));
const EditRoles = React.lazy(() => import('./views/RolePermission/EditRoles'));
const SiteSettings = React.lazy(() => import('./views/Setting/Setting'));
const ContactusForm = React.lazy(() => import('./views/Contactus/ContactusForm'));
const ContactusLog = React.lazy(() => import('./views/Contactus/ContactusLog'));
const Profile = React.lazy(() => import('./views/User/Profile'));
const Chat = React.lazy(() => import('./views/Chat/Chat'));
const EmailTemplate = React.lazy(() => import('./views/EmailTemplate/EmailTemplate'));
const EditEmailTemplate = React.lazy(() => import('./views/EmailTemplate/EditEmailTemplate'));
const Admins = React.lazy(() => import('./views/Admin/Admins'));
const AddAdmin = React.lazy(() => import('./views/Admin/AddAdmin'));
const EditAdmin = React.lazy(() => import('./views/Admin/EditAdmin'));
const Notifications = React.lazy(() => import('./views/Notification/Notifications'));

// routes
const routes = [
    // { path: '/', access: true, exact: true, title: 'Template', layout: Guest, component: Template },
    { path: '/', access: true, exact: true, title: 'Template', layout: Guest, component: LoginPage },
    { path: '/login', access: true, exact: true, title: 'Login Page', layout: Guest, component: LoginPage },
    { path: '/register', access: true, exact: true, title: 'Register Page', layout: Guest, component: RegisterPage },
    { path: '/forgot-password', access: true, exact: true, title: 'Login Page', layout: Guest, component: ForgetPassword },
    { path: '/change-password/:token', access: true, exact: true, title: 'Login Page', layout: Guest, component: ChangePassword },
    { path: '/dashboard', access: true, exact: true, title: 'Dashboard', layout: Authenticate, component: Dashboard },
    { path: '/country', access: true, exact: true, title: 'Country', layout: Authenticate, component: Country },
    { path: '/add-country', access: true, exact: true, title: 'Add Country', layout: Authenticate, component: AddCountries },
    { path: '/edit-country/:id', access: true, exact: true, title: 'Edit Country', layout: Authenticate, component: EditCountry },
    { path: '/store', access: true, exact: true, title: 'Store', layout: Authenticate, component: Store },
    { path: '/add-store', access: true, exact: true, title: 'Add Store', layout: Authenticate, component: AddStore },
    { path: '/edit-store/:id', access: true, exact: true, title: 'Edit Store', layout: Authenticate, component: EditStore },
    { path: '/state', access: true, exact: true, title: 'State', layout: Authenticate, component: State },
    { path: '/add-state', access: true, exact: true, title: 'Add State', layout: Authenticate, component: AddState },
    { path: '/edit-state/:id', access: true, exact: true, title: 'Edit State', layout: Authenticate, component: EditState },
    { path: '/faq', access: true, exact: true, title: 'Faq', layout: Authenticate, component: Faq },
    { path: '/add-faq', access: true, exact: true, title: 'Add Faq', layout: Authenticate, component: AddFaq },
    { path: '/edit-faq/:id', access: true, exact: true, title: 'Edit Faq', layout: Authenticate, component: EditFaq },
    { path: '/city', access: true, exact: true, title: 'City', layout: Authenticate, component: City },
    { path: '/add-city', access: true, exact: true, title: 'Add City', layout: Authenticate, component: AddCity },
    { path: '/edit-city/:id', access: true, exact: true, title: 'Edit City', layout: Authenticate, component: EditCity },
    { path: '/kvi-overview', access: true, exact: true, title: 'Kvi Overview', layout: Authenticate, component: KviOverview },
    { path: '/incentive', access: true, exact: true, title: 'Incentive', layout: Authenticate, component: IncentivePage },
    { path: '/wizard', access: true, exact: true, title: 'Wizard', layout: Authenticate, component: Wizard },
    { path: '/roles-permissions', access: true, exact: true, title: 'Roles & Permissions', layout: Authenticate, component: RolesPermissions },
    { path: '/add-role', access: true, exact: true, title: 'Add Role', layout: Authenticate, component: AddRoles },
    { path: '/edit-role/:id', access: true, exact: true, title: 'Edit Role', layout: Authenticate, component: EditRoles },
    { path: '/user-management', access: true, exact: true, title: 'Users', layout: Authenticate, component: Users },
    { path: '/add-user', access: true, exact: true, title: 'Add User', layout: Authenticate, component: AddUser },

    { path: '/edit-user/:id', access: true, exact: true, title: 'Add User', layout: Authenticate, component: EditUser },

    { path: '/reports', access: true, exact: true, title: 'Reports Page', layout: Authenticate, component: ReportsPage },
    { path: '/settings', access: true, exact: true, title: 'Site Settings Page', layout: Authenticate, component: SiteSettings },
    { path: '/contact-us', access: true, exact: true, title: 'Contact-us Form', layout: Authenticate, component: ContactusForm },
    { path: '/contact-us-log', access: true, exact: true, title: 'Contact-us Log', layout: Authenticate, component: ContactusLog },
    { path: '/faqs', access: true, exact: true, title: 'Faqs Page', layout: Authenticate, component: Faq },

    { path: '/profile', access: true, exact: true, title: 'Edit Profile', layout: Authenticate, component: Profile },
    { path: '/chat', access: true, exact: true, title: 'Chat', layout: Authenticate, component: Chat },

    { path: '/email-template', access: true, exact: true, title: 'Email Template', layout: Authenticate, component: EmailTemplate },
    { path: '/edit-email-template/:id', access: true, exact: true, title: 'Edit Email Template', layout: Authenticate, component: EditEmailTemplate },

    { path: '/admin-management', access: true, exact: true, title: 'Admins', layout: Authenticate, component: Admins },
    { path: '/add-admin', access: true, exact: true, title: 'Add Admin', layout: Authenticate, component: AddAdmin },
    { path: '/edit-admin/:id', access: true, exact: true, title: 'Edit Admin', layout: Authenticate, component: EditAdmin },
    { path: '/notifications', access: true, exact: true, title: 'Notifications', layout: Authenticate, component: Notifications },
];

export default routes;