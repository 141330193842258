// import { isArrowFunction } from 'typescript';
import { BEFORE_FAQ, DELETE_FAQ, GET_FAQ, EDIT_FAQ,GET_FAQS, CREATE_FAQ } from './Types';

const initialState = {
    getFaqsAuth: false,
    faq: null,
    delFaqAuth: false,
    createFaqAuth: false,
    getFaqAuth: false,
    editFaqAuth: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_FAQ:
            return {
                ...state,
                getFaq: action.payload,
                getFaqAuth: true
            }
        case CREATE_FAQ:
            return {
                ...state,
                createFaqAuth: true
            }
        case GET_FAQS:
            return {
                ...state,
                faqsList: action.payload,
                getFaqsAuth: true
            }
        case EDIT_FAQ:
            return {
                ...state,
                faq: action.payload,
                editFaqAuth: true
            }
        case DELETE_FAQ:
            return {
                ...state,
                faq: action.payload,
                delFaqAuth: true
            }
        case BEFORE_FAQ:
            return {
                ...state,
                getFaqsAuth: false,
                faq: null,
                delFaqAuth: false,
                createFaqAuth: false,
                getFaqAuth: false,
                editFaqAuth: false
            }
        default:
            return {
                ...state
            }
    }
}