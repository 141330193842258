// import { isArrowFunction } from 'typescript';
import { GET_USER_PERMISSIONS, GET_ERRORS, GET_ROLES, CREATE_ROLE, GET_ROLE, EDIT_ROLE, BEFORE_ROLE, DELETE_ROLE, GET_ALL_PERMISSIONS,GET_ROLE_PERMISSIONS } from './Types';

const initialState = {
    getRolesAuth: false,
    rolesList: [],
    role: null,
    delRoleAuth: false,
    createRoleAuth: false,
    getRoleAuth: false,
    editRoleAuth: false,
    getPermissionsAuth: false,
    getRolePermissionsAuth:false,
    getUserPermissionsAuth:false,
    getRolePermissions:[],
    geUserPermissions:[]
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_ROLE:
            return {
                ...state,
                getRole: action.payload,
                getRoleAuth: true
            }
        case CREATE_ROLE:
            return {
                ...state,
                createRoleAuth: true
            }
        case GET_ROLES:
            return {
                ...state,
                rolesList: action.payload,
                getRolesAuth: true
            }
        case EDIT_ROLE:
            return {
                ...state,
                role: action.payload,
                editRoleAuth: true
            }
        case DELETE_ROLE:
            return {
                ...state,
                role: action.payload,
                delRoleAuth: true
            }
        case GET_ALL_PERMISSIONS:
            return {
                ...state,
                permissionList: action.payload,
                getPermissionsAuth: true
            }
            case GET_ROLE_PERMISSIONS:
            return {
                ...state,
                getRolePermissions: action.payload,
                getRolePermissionsAuth: true
            }
            case GET_USER_PERMISSIONS:
                return {
                    ...state,
                    getUserPermissions: action.payload,
                    getUserPermissionsAuth: true
                }
        case BEFORE_ROLE:
            return {
                ...state,
                getRolesAuth: false,
                role: null,
                delRoleAuth: false,
                createRoleAuth: false,
                getRoleAuth: false,
                editRoleAuth: false,
                getRolePermissionsAuth:false,
                getUserPermissionsAuth:false,
                getRolePermissions:[],
                getUserPermissions:[]
            }
        default:
            return {
                ...state
            }
    }
}