// import React, { Component } from "react";

// class Layout1 extends Component {
//     render() {
//         return (
//             <>
//                 <div>
//                     <div className="main">
//                         {this.props.children}
//                     </div>
//                 </div>
//             </>
//         );
//     }
// }

// export default Layout1;




import React, { useEffect , useState} from "react";
import FullPageLoader from '../components/FullPageLoader/FullPageLoader'
const Layout1 = (props)=> {

  const [loader, setLoader] = useState(false)
  let accessToken = localStorage.getItem("auth")
  useEffect(() => {
    if (accessToken) {
      setLoader(true)
      window.location.href = '/dashboard';
    }
  },[])

    return (
      <div className="wrapper login-wrapper">
        {
          loader? <FullPageLoader/> :  
           <>
          <div>
              <div className="main">
                  {props.children}
              </div>
          </div>
      </>
        }
        
      </div>
    );
}

export default Layout1;
