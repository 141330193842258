// ERRORS
export const EMPTY_ERRORS = 'EMPTY_ERRORS';
export const GET_ERRORS = 'GET_ERRORS';

// STATES
export const GET_STORES = 'GET_STORES'
export const BEFORE_STORE = 'BEFORE_STORE'
export const DELETE_STORE = 'DELETE_STORE'
export const CREATE_STORE = 'CREATE_STORE'
export const GET_STORE = 'GET_STORE'
export const EDIT_STORE = 'EDIT_STORE'
