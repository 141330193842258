import logo from '../assets/images/Logo.png';
// menu
import dashboard from '../assets/images/dashboard.svg';
import dashboardYellow from '../assets/images/dashboard-yellow.svg';
import overview from '../assets/images/overview.svg';
import overviewYellow from '../assets/images/overview-yellow.svg';
import incentive from '../assets/images/incentive.svg';
import incentiveYellow from '../assets/images/incentive-yellow.svg';
import chat from '../assets/images/chat.svg';
import chatYellow from '../assets/images/chat-yellow.svg';
import profile from '../assets/images/profile.svg';
import profileLocal from '../assets/images/profile.jpg';
import profileYellow from '../assets/images/profile-yellow.svg';
import settings from '../assets/images/settings.png';
import settingsYellow from '../assets/images/settings-yellow.png';
import help from '../assets/images/help.svg';
import helpYellow from '../assets/images/help-yellow.svg';
import signout from '../assets/images/signout.svg';
import signoutYellow from '../assets/images/signout-yellow.svg';
// search bar
import notification from '../assets/images/notifications.svg';
import darkMode from '../assets/images/dark-mode.svg';
import defaultPic from '../assets/images/profile-pic.png';
import searchIcon from '../assets/images/search.svg'
/**/
import clipBoard from '../assets/images/material-symbols_sell.svg'
import wizard from '../assets/images/wizard-pic.svg'
import dropdownIcon from '../assets/images/dropdown-icon.svg'

export const Images = {  
	logo,
	dashboard,
	dashboardYellow,
	overview,
	overviewYellow,
	incentive,
	incentiveYellow,
	chat,
	chatYellow,
	profile,
	profileYellow,
	settings,
	settingsYellow,
	help,
	helpYellow,
	signout,
	signoutYellow,
	notification,
	darkMode,
	defaultPic,
	searchIcon,
	clipBoard,
	wizard,
	dropdownIcon
}