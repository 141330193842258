import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import { Images } from '../../assets/asset';

import './sidebar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faGlobe, faClipboardQuestion, faEarthAmerica, faKey, faStore, faMapLocationDot, faUsers, faAddressBook, faIdCard, faEnvelope,faNoteSticky, faXmark } from '@fortawesome/free-solid-svg-icons';

const Sidebar = () => {

    const { pathname } = useLocation();
    const [menu, setMenu] = useState(false);
    const [helpSupport, setHelpSupport] = useState(false);
    const [helpSetting, setHelpSetting] = useState(false);
    const [permissions, setPermissions] = useState(localStorage.getItem("permissions") ? JSON.parse(localStorage.getItem("permissions") || "[]") : '')

    const menuToggle = () => {
        setMenu((menu) => !menu);
    }
    return (
        <div className={`sidebar ${menu ? "open" : ""}`}>
            <button className='sidebar-button' onClick={menuToggle}>
                <FontAwesomeIcon icon={faBars} className='bars-icon' />
                <FontAwesomeIcon icon={faXmark} className='cross-icon' />
            </button>
            <div className='logo'>
                <Link to="/dashboard">
                     <img className='img-fluid' src={Images.logo} alt="logo" />
                </Link>
            </div>
            <div className='main-menu'>
                <div className='menu'>
                    <div className='main-head'>
                        <span>Main Pages</span>
                    </div>
                    <ul>
                        {
                            permissions && permissions['View Dashboard'] && 
                            <li className={`nav-item ${pathname?.toLowerCase() === '/dashboard' ? 'active' : ''}`}>
                                <Link to="/dashboard" >
                                    <span className='menu-img'>
                                        <img className='img-fluid grey' alt="" src={Images.dashboard} />
                                        <img className='img-fluid yellow' alt="" src={Images.dashboardYellow} />
                                    </span>
                                    <span className='menu-text'>
                                        Dashboard
                                    </span>
                                </Link>
                            </li>
                        }

                        {
                            permissions && permissions['View Country'] && 
                            <li className={`nav-item ${pathname?.toLowerCase() === '/country' || pathname?.toLowerCase() === '/add-country' || pathname?.toLowerCase().includes('/edit-country') ? 'active' : ''}`}>
                                <Link to="/country" >
                                    <span className='menu-img'>
                                        <FontAwesomeIcon icon={faGlobe} />
                                    </span>
                                    <span className='menu-text'>
                                        Country Management
                                    </span>
                                </Link>
                            </li>
                        }

                        {
                            permissions && permissions['View State'] && 
                            <li className={`nav-item ${pathname?.toLowerCase() === '/state' || pathname?.toLowerCase() === '/add-state' || pathname?.toLowerCase().includes('/edit-state') ? 'active' : ''}`}>
                            <Link to="/state" >
                                <span className='menu-img'>
                                    <FontAwesomeIcon icon={faEarthAmerica} />
                                </span>
                                <span className='menu-text'>
                                    State Management
                                </span>
                            </Link>
                            </li>
                        }

                       { 
                            permissions && permissions['View City'] && 
                            <li className={`nav-item ${pathname?.toLowerCase() === '/city' || pathname?.toLowerCase() === '/add-city' || pathname?.toLowerCase().includes('/edit-city')? 'active' : ''}`}>
                                <Link to="/city" >
                                    <span className='menu-img'>
                                        <FontAwesomeIcon icon={faMapLocationDot} />
                                    </span>
                                    <span className='menu-text'>
                                        City Management
                                    </span>
                                </Link>
                            </li>
                        }


                        {
                            permissions && permissions['View Store'] && 
                            <li className={`nav-item ${pathname?.toLowerCase() === '/store' || pathname?.toLowerCase() === '/add-store' || pathname?.toLowerCase().includes('/edit-store')? 'active' : ''}`}>
                                <Link to="/store" >
                                    <span className='menu-img'>
                                        <FontAwesomeIcon icon={faStore} /> 
                                    </span>
                                    <span className='menu-text'>
                                        Store Management
                                    </span>
                                </Link>
                            </li>
                        }

                        {
                            permissions && permissions['View User'] && 
                            <li className={`nav-item ${pathname?.toLowerCase() === '/user-management' || pathname?.toLowerCase() === '/add-user' || pathname?.toLowerCase().includes('/edit-user')? 'active' : ''}`}>
                                <Link to="/user-management" >
                                    <span className='menu-img'>
                                        <FontAwesomeIcon icon={faUsers} />
                                    </span>
                                    <span className='menu-text'>
                                        User Management
                                    </span>
                                </Link>
                            </li>
                        } 

                        {
                            permissions && permissions['View Admin'] && 
                            <li className={`nav-item ${pathname?.toLowerCase() === '/admin-management' || pathname?.toLowerCase() === '/add-admin' || pathname?.toLowerCase().includes('/edit-admin')? 'active' : ''}`}>
                                <Link to="/admin-management" >
                                    <span className='menu-img'>
                                        <img className='img-fluid grey' alt="" src={Images.profile} />
                                        <img className='img-fluid yellow' alt="" src={Images.profileYellow} />
                                    </span>
                                    <span className='menu-text'>
                                        Admin Management
                                    </span>
                                </Link>
                            </li>
                        }  

                        {
                            permissions && permissions['View Role'] && 
                            <li className={`nav-item ${pathname?.toLowerCase() === '/roles-permissions' || pathname?.toLowerCase() === '/add-role' || pathname?.toLowerCase().includes('/edit-role')? 'active' : ''}`}>
                                <Link to="/roles-permissions" >
                                    <span className='menu-img'>
                                        <FontAwesomeIcon icon={faKey} />
                                    </span>
                                    <span className='menu-text'>
                                        Role & Permissions
                                    </span>
                                </Link>
                            </li>
                        }

                        {
                            permissions && permissions['View KPI Overview'] && 
                            <li className={`nav-item ${pathname?.toLowerCase() === '/kvi-overview' ? 'active' : ''}`}>
                                <Link to="/kvi-overview" >
                                    <span className='menu-img'>
                                        <img className='img-fluid grey' alt="" src={Images.overview} />
                                        <img className='img-fluid yellow' alt="" src={Images.overviewYellow} />
                                    </span>
                                    <span className='menu-text'>
                                        KPI Overview
                                    </span>
                                </Link>
                            </li>
                        }

                        {
                            permissions && permissions['View Incentive'] && 
                            <li className={`nav-item ${pathname?.toLowerCase() === '/incentive' ? 'active' : ''}`}>
                                <Link to="/incentive" >
                                    <span className='menu-img'>
                                        <img className='img-fluid grey' alt="" src={Images.incentive} />
                                        <img className='img-fluid yellow' alt="" src={Images.incentiveYellow} />
                                    </span>
                                    <span className='menu-text'>
                                        Incentives
                                    </span>
                                </Link>
                            </li>
                        }

                        {
                            permissions && permissions['View Messages'] &&
                            <li className={`nav-item ${pathname?.toLowerCase() === '/chat' ? 'active' : ''}`}>
                                <Link to="/chat" >
                                    <span className='menu-img'>
                                        <img className='img-fluid grey' alt="" src={Images.chat} />
                                        <img className='img-fluid yellow' alt="" src={Images.chatYellow} />
                                    </span>
                                    <span className='menu-text'>
                                        Chat
                                    </span>
                                </Link>
                            </li>
                        }
                       
                        
                    </ul>
                </div>
                <div className='menu'>
                    <div className='main-head'>
                        <span>Other Pages</span>
                    </div>
                    <ul>

                        <li className={`nav-item ${pathname?.toLowerCase() === '' ? 'active' : ''}`}>
                            {
                                // props.showHelpSetting &&
                                <div onClick={() => setHelpSetting(!helpSetting)} className={`${helpSetting ? 'open' : ''} sub-menu-button d-flex`}>
                                    <Link to="" >
                                        <span className='menu-img'>
                                            <img className='img-fluid grey' alt="" src={Images.settings} />
                                            <img className='img-fluid yellow' alt="" src={Images.settingsYellow} />
                                        </span>
                                        <span className='menu-text'>
                                            Account Settings
                                        </span>
                                    </Link>
                                    <div className="tab-icon"></div>
                                </div>
                            }
                        </li>
                        {
                            helpSetting &&
                            <ul className='sub-menu'>
                                {
                                    permissions && permissions['Edit Account Setting'] &&
                                    <li className={`nav-item ${pathname?.toLowerCase() === '/settings' ? 'active' : ''}`}>
                                        <Link to="/settings" >
                                            <span className='menu-img'>
                                                <img className='img-fluid grey' alt="" src={Images.settings} />
                                                <img className='img-fluid yellow' alt="" src={Images.settingsYellow} />
                                            </span>
                                            <span className='menu-text'>
                                                Site Settings
                                            </span>
                                        </Link>
                                    </li>
                                }

                                {
                                    permissions && permissions['View Templates'] &&
                                    <li className={`nav-item ${pathname?.toLowerCase() === '/email-template' || pathname?.toLowerCase().includes('/edit-email-template')? 'active' : ''}`}>
                                        <Link to="/email-template" >
                                            <span className='menu-img'>
                                                <FontAwesomeIcon icon={faEnvelope} />
                                            </span>
                                            <span className='menu-text'>
                                                Email Templates
                                            </span>
                                        </Link>
                                    </li>
                                }  
                                
                                {
                                    permissions && permissions['View Notifications'] &&
                                    <li className={`nav-item ${pathname?.toLowerCase() === '/notifications'? 'active' : ''}`}>
                                        <Link to="/notifications" >
                                            <span className='menu-img'>
                                                <FontAwesomeIcon icon={faNoteSticky} />
                                            </span>
                                            <span className='menu-text'>
                                              Notifications
                                            </span>
                                        </Link>
                                    </li>
                                }    
                            </ul>
                    
                        }
                       
                        <li className={`nav-item ${pathname?.toLowerCase() === '' ? 'active' : ''}`}>
                            {
                                // props.showHelpSupport &&
                                <div onClick={() => setHelpSupport(!helpSupport)} className={`${helpSupport ? 'open' : ''} sub-menu-button d-flex`}>
                                    <Link to="" >
                                        <span className='menu-img'>
                                            <img className='img-fluid grey' alt="" src={Images.help} />
                                            <img className='img-fluid yellow' alt="" src={Images.helpYellow} />
                                        </span>
                                        <span className='menu-text'>
                                            Help & Support
                                        </span>
                                    </Link>
                                    <div className="tab-icon"></div>
                                </div>
                            }
                        </li>
                        {
                            helpSupport &&
                            <ul className='sub-menu'>


                                {
                                    permissions && permissions['View FAQ'] &&
                                    <li className={`nav-item ${pathname?.toLowerCase() === '/faqs' || pathname?.toLowerCase() === '/add-faq' || pathname?.toLowerCase().includes('/edit-faq')? 'active' : ''}`}>
                                        <Link to="/faqs" >
                                            <span className='menu-img'>
                                                <FontAwesomeIcon icon={faClipboardQuestion} />
                                            </span>
                                            <span className='menu-text'>
                                                FAQs
                                            </span>
                                        </Link>
                                    </li>
                                }

                                {
                                    permissions && permissions['View Contact-us Log'] &&
                                    <li className={`nav-item ${pathname?.toLowerCase() === '/contact-us-log' ? 'active' : ''}`}>
                                        <Link to="/contact-us-log" >
                                            <span className='menu-img'>
                                                <FontAwesomeIcon icon={faIdCard} />
                                            </span>
                                            <span className='menu-text'>
                                                Contact us Log
                                            </span>
                                        </Link>
                                    </li>
                                }
                                {
                                   permissions && permissions['Send Contact-us Email'] && 
                                    <li className={`nav-item ${pathname?.toLowerCase() === '/contact-us' ? 'active' : ''}`}>
                                        <Link to="/contact-us" >
                                            <span className='menu-img'>
                                                <FontAwesomeIcon icon={faAddressBook} />
                                            </span>
                                            <span className='menu-text'>
                                                Contact us
                                            </span>
                                        </Link>
                                    </li>
                                }
                            </ul>
                        }
                    </ul>
                </div>
            </div>
            {/* <div className='signout-section'>
                <Link to="" onClick={() => { localStorage.removeItem("auth"); window.location.replace('/'); }} >
                    <span className='menu-img'>
                        <img className='img-fluid grey' alt="" src={Images.signout} />
                        <img className='img-fluid yellow' alt="" src={Images.signoutYellow} />
                    </span>
                    <span className='menu-text'>
                        Signout
                    </span>
                </Link>
            </div> */}
        </div>
    )
}

export default Sidebar