import { toast } from 'react-toastify';

import {
    GET_ERRORS, GET_USER, CREATE_USER, GET_USERS, GET_ADMINS, EDIT_USER,
    BEFORE_USER, DELETE_USER, UPDATE_STATUS, APPROVED_USER, GET_PARENT_USERS, GET_ALL_PARENT_USERS, GET_ACTIVE_USER
} from './Types';
import { emptyError } from '../../redux/shared/error/error.action';
import { ENV } from '../../config/config';

export const beforeUser = () => {
    return {
        type: BEFORE_USER
    }
}

export const getUsers = (qs = '', body = {}) => dispatch => {
    dispatch(emptyError());

    let url = `${ENV.url}users`;
    if (qs)
        url += `?${qs}`
    fetch(url, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'x-auth-user': ENV.x_auth_user
        },
        body: JSON.stringify(body)
    }).then(res => res.json()).then(data => {

        if (data.success) {
            dispatch({
                type: GET_USERS,
                payload: data
            })
        }
    }).catch(error => {

        if (error.response && error.response.data) {
            const { data } = error.response
            if (data.message)
                toast.error(data.message)

        }
        dispatch({
            type: GET_ERRORS,
            payload: error,

        })
    })
};

export const getAdmins = (qs = '', body = {}) => dispatch => {
    dispatch(emptyError());

    let url = `${ENV.url}admin-users`;
    if (qs)
        url += `?${qs}`
    fetch(url, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'x-auth-user': ENV.x_auth_user
        },
        body: JSON.stringify(body)
    }).then(res => res.json()).then(data => {

        if (data.success) {
            dispatch({
                type: GET_ADMINS,
                payload: data
            })
        }
    }).catch(error => {

        if (error.response && error.response.data) {
            const { data } = error.response
            if (data.message)
                toast.error(data.message)

        }
        dispatch({
            type: GET_ERRORS,
            payload: error,

        })
    })
};

export const addUser = (body) => dispatch => {
    dispatch(emptyError());
    const url = `${ENV.url}create-user`;

    fetch(url, {
        method: 'POST',
        headers: {
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'x-auth-user': ENV.x_auth_user
        },
        body
    }).then(res => res.json()).then(data => {
        if (data.success) {
            toast.success(data.message)
            dispatch({
                type: CREATE_USER,
                payload: data
            })
        } else {
            toast.error(data.data.error)
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(error => {
        if (error.response && error.response.data) {
            const { data } = error.response
            if (data.message)
                toast.error(data.message)
        }
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
};


export const deleteUser = (id) => dispatch => {
    dispatch(emptyError());
    let url = `${ENV.url}delete-user?id=${id}`;

    fetch(url, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'x-auth-user': ENV.x_auth_user
        }
    }).then(res => res.json()).then(data => {
        if (data.success) {
            toast.success(data.message)
            dispatch({
                type: DELETE_USER,
                payload: data
            })
        } else {

            toast.error(data.data.error)
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(error => {

        if (error.response && error.response.data) {
            const { data } = error.response

            if (data.message)
                toast.error(data.message)
        }
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
};


export const getUser = (userId) => dispatch => {
    dispatch(emptyError());
    let url = `${ENV.url}get-user/${userId}`;

    fetch(url, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'x-auth-user': ENV.x_auth_user
        }
    }).then(res => res.json()).then(data => {
        if (data.success) {
            //toast.success(data.message)
            dispatch({
                type: GET_USER,
                payload: data
            })
        } else {
            toast.error(data.message)
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(error => {
        if (error.response && error.response.data) {
            const { data } = error.response
            if (data.message)
                toast.error(data.message)
        }
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
};

export const updateUser = (body) => dispatch => {

    dispatch(emptyError());
    const url = `${ENV.url}update-user`;

    fetch(url, {
        method: 'POST',
        headers: {
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'x-auth-user': ENV.x_auth_user
        },
        body
    }).then(res => res.json()).then(data => {
        if (data.success) {
            localStorage.setItem('userImage', data.data.image);
            toast.success(data.message)
            dispatch({
                type: EDIT_USER,
                payload: data
            })
        } else {
            toast.error(data.data)
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(error => {
        if (error.response && error.response.data) {
            const { data } = error.response
            if (data.message)
                toast.error(data.message)
        }
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
};


export const getParentUsers = (body) => dispatch => {
    dispatch(emptyError());
    fetch(ENV.url + 'get-parent-users', {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'x-auth-user': ENV.x_auth_user

        },
        body: JSON.stringify(body)
    }).then(res => res.json()).then(data => {

        if (data.success) {
            // toast.success(data.message);
            dispatch({
                type: GET_PARENT_USERS,
                payload: data
            });
        } else {
            toast.error(data.message)

            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(error => {
        if (error.response && error.response.data) {
            const { data } = error.response
            if (data.message)
                toast.error(data.message)
        }
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
}


export const updateUserStatus = (body) => dispatch => {
    dispatch(emptyError());
    fetch(ENV.url + 'update-user-status', {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'x-auth-user': ENV.x_auth_user

        },
        body: JSON.stringify(body)
    }).then(res => res.json()).then(data => {

        if (data.success) {
            // toast.success(data.message);
            dispatch({
                type: UPDATE_STATUS,
                payload: data
            });
        } else {
            toast.error(data.message)

            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(error => {
        if (error.response && error.response.data) {
            const { data } = error.response
            if (data.message)
                toast.error(data.message)
        }
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
}



export const approveUserStatus = (body) => dispatch => {


    dispatch(emptyError());
    fetch(ENV.url + 'approve-user', {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'x-auth-user': ENV.x_auth_user

        },
        body: JSON.stringify(body)
    }).then(res => res.json()).then(data => {

        if (data.success) {
            // toast.success(data.message);
            dispatch({
                type: APPROVED_USER,
                payload: data
            });
        } else {
            toast.error(data.message)

            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(error => {
        if (error.response && error.response.data) {
            const { data } = error.response
            if (data.message)
                toast.error(data.message)
        }
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
}


export const getActiveUser = (userId) => dispatch => {
    dispatch(emptyError());
    let url = `${ENV.url}get-user/${userId}`;

    fetch(url, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'x-auth-user': ENV.x_auth_user
        }
    }).then(res => res.json()).then(data => {
        if (data.success) {
            //toast.success(data.message)
            dispatch({
                type: GET_ACTIVE_USER,
                payload: data
            })
        } else {
            if(data.message === "Unauthenticated."){
                localStorage.removeItem("auth");
                localStorage.removeItem("permissions"); 
                localStorage.removeItem('userID')
                window.location.replace('/'); 
            }
            toast.error(data.message)
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(error => {
        if (error.response && error.response.data) {
            const { data } = error.response
            if (data.message)
                toast.error(data.message)
        }
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
};


