// ERRORS
export const EMPTY_ERRORS = 'EMPTY_ERRORS';
export const GET_ERRORS = 'GET_ERRORS';

// COUNTRY
export const GET_COUNTRIES = 'GET_COUNTRIES'
export const BEFORE_COUNTRY = 'BEFORE_COUNTRY'
export const DELETE_COUNTRY = 'DELETE_COUNTRY'
export const CREATE_COUNTRY = 'CREATE_COUNTRY'
export const GET_COUNTRY = 'GET_COUNTRY'
export const EDIT_COUNTRY = 'EDIT_COUNTRY'
