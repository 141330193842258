// import React, { Component } from "react";
import Sidebar from "../components/Sidebar/sidebar";
import SearchBar from "../components/searchBar/searchbar";
import { Link, useNavigate } from "react-router-dom";
import mobileLogo from "../assets/images/Logo.png";
import { Image } from "react-bootstrap";

// class Layout2 extends Component {
//     render() {
//         return (
//             <>

//                 <div>
//                     <div className="main-wrapper d-flex">
//                         <Sidebar />
//                          <div className="content-area">
//                             <SearchBar />
//                             {this.props.children}
//                          </div>
//                     </div>
//                 </div>
//             </>
//         );
//     }
// }

// export default Layout2;





import React, { useEffect } from "react";
// import FullPageLoader from '../components/FullPageLoader/FullPageLoader'
import { getSetting } from "../views/Setting/Setting.action";
import { useDispatch, useSelector } from "react-redux";
const Layout2 = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();



  useEffect(() => {
    dispatch(getSetting());

  }, [dispatch])

  const setting = useSelector((state) => state?.setting?.getSetting);

  useEffect(() => {
    if (setting) {

    }
  }, [setting])




  return (
    <div className="wrapper login-wrapper">
      {
        // loader ? <FullPageLoader /> :
        <>
          {
            localStorage.getItem("auth") ?
              <div>

                <div className="main-wrapper d-flex">
                  <Sidebar />
                  <div className="content-area">
                    <div className="mobile-logo-area">
                      <div className="mobile-logo-wrapper">
                        <Link to="/" className="mobile-logo">
                          <Image src={mobileLogo} className="img-fluid" alt="mobile-logo" />
                        </Link>
                      </div>
                    </div>
                    <SearchBar />
                    {props.children}
                  </div>
                </div>
              </div>
              : navigate('/')
          }
        </>
      }

    </div>
  );
}

export default Layout2;
