// ERRORS
export const EMPTY_ERRORS = 'EMPTY_ERRORS';
export const GET_ERRORS = 'GET_ERRORS';

// STATES

export const GET_NOTIFICATION = 'GET_NOTIFICATION'
export const GET_LISTING_NOTIFICATION = 'GET_LISTING_NOTIFICATION'
export const BEFORE_NOTIFICATION = 'BEFORE_NOTIFICATION'
export const MARK_AS_READ_NOTIFICATION = 'MARK_AS_READ_NOTIFICATION'
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION'
export const MARK_AS_READ_ALL_NOTIFICATION = 'MARK_AS_READ_ALL_NOTIFICATION'
