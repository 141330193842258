// import { isArrowFunction } from 'typescript';
import {GET_STORES, CREATE_STORE, GET_STORE, EDIT_STORE,BEFORE_STORE, DELETE_STORE} from './Types';

const initialState = {
    getStoresAuth: false,
    stores: null,
    delStoreAuth: false,
    createStoreAuth: false,
    getStoreAuth: false,
    editStoreAuth: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_STORE:
            return {
                ...state,
                getStore: action.payload,
                getStoreAuth: true
            }
        case CREATE_STORE:
            return {
                ...state,
                createStoreAuth: true
            }
        case GET_STORES:
            return {
                ...state,
                StoresList: action.payload,
                getStoresAuth: true
            }
        case EDIT_STORE:
            return {
                ...state,
                Store: action.payload,
                editStoreAuth: true
            }
        case DELETE_STORE:
            return {
                ...state,
                Store: action.payload,
                delStoreAuth: true
            }
        case BEFORE_STORE:
            return {
                ...state,
                getStoresAuth: false,
                stores: null,
                delStoreAuth: false,
                createStoreAuth: false,
                getStoreAuth: false,
                editStoreAuth: false
            }
        default:
            return {
                ...state
            }
    }
}