// import { isArrowFunction } from 'typescript';
import { GET_ERRORS,GET_USER_EMAIL_TEMPLATES, GET_USER_EMAIL_TEMPLATE, EDIT_USER_EMAIL_TEMPLATE,BEFORE_USER_EMAIL_TEMPLATE} from './Types';

const initialState = {
    getEmailTemplateAuth: false,
    EmailTemplate: null,
    delEmailTemplateAuth: false,
    createEmailTemplateAuth: false,
    getEmailTemplateAuth: false,
    editEmailTemplateAuth: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_USER_EMAIL_TEMPLATE:
            return {
                ...state,
                getEmailTemplate: action.payload,
                getEmailTemplateAuth: true
            }
        case GET_USER_EMAIL_TEMPLATES:
            return {
                ...state,
                EmailTemplateList: action.payload,
                getEmailTemplatesAuth: true
            }
        case EDIT_USER_EMAIL_TEMPLATE:
            return {
                ...state,
                EmailTemplate: action.payload,
                editEmailTemplateAuth: true
            }

        case BEFORE_USER_EMAIL_TEMPLATE:
            return {
                ...state,
                getEmailTemplateAuth: false,
                EmailTemplate: null,
                delEmailTemplateAuth: false,
                createEmailTemplateAuth: false,
                getEmailTemplatesAuth: false,
                editEmailTemplateAuth: false
            }
        default:
            return {
                ...state
            }
    }
}