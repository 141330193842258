// ERRORS
export const EMPTY_ERRORS = 'EMPTY_ERRORS';
export const GET_ERRORS = 'GET_ERRORS';

// CONTACTUS

export const GET_CONTACTUS = 'GET_CONTACTUS'
export const BEFORE_CONTACTUS = 'BEFORE_CONTACTUS'
export const DELETE_CONTACTUS = 'DELETE_CONTACTUS'
export const CREATE_CONTACTUS = 'CREATE_CONTACTUS'
