// import { isArrowFunction } from 'typescript';
import { GET_ADMIN } from '../../redux/types';
import {GET_ACTIVE_USER, GET_USERS,GET_ADMINS,GET_ALL_PARENT_USERS, CREATE_USER, GET_USER, EDIT_USER, BEFORE_USER, DELETE_USER, UPDATE_STATUS,APPROVED_USER, GET_PARENT_USERS } from './Types';

const initialState = {
    getUsersAuth: false,
    getActiveUserAuth: false,
    Users: null,
    Admins:null,
    UsersList:null,
    AdminsList:null,
    delUserAuth: false,
    createUserAuth: false,
    getUserAuth: false,
    editUserAuth: false,
    getParentUsersAuth: false,
    ParentUsersList: [],
    AllParentUsersList: [],
    updatedAuthUserStatus: false,
    approved_user: false,
    getAllParentUsersAuth: false
}

export default function (state = initialState, action) {

    switch (action.type) {
        case GET_USER:
            return {
                ...state,
                getUser: action.payload,
                getUserAuth: true
            }
        case CREATE_USER:
            return {
                ...state,
                createUserAuth: true
            }
        case GET_USERS:
            return {
                ...state,
                UsersList: action.payload,
                getUsersAuth: true
            }
            case GET_ACTIVE_USER:
                return {
                    ...state,
                    activeUser: action.payload,
                    getActiveUserAuth: true
                }
        case GET_ADMINS:
            return {
                ...state,
                AdminsList: action.payload,
                getUsersAuth: true
            }
        case EDIT_USER:
            return {
                ...state,
                User: action.payload,
                editUserAuth: true
            }
        case DELETE_USER:
            return {
                ...state,
                User: action.payload,
                delUserAuth: true
            }
        case GET_PARENT_USERS:
            return {
                ...state,
                ParentUsersList: action.payload,
                getParentUsersAuth: true
            }
            case GET_ALL_PARENT_USERS:
                return {
                    ...state,
                    AllParentUsersList: action.payload,
                    getAllParentUsersAuth: true
                }
        case UPDATE_STATUS:
            return {
                ...state,
                User: action.payload,
                updatedAuthUserStatus: true
            }
        case APPROVED_USER:
            return {
                ...state,
                // User: action.payload,
                approved_user: true
            }
        case BEFORE_USER:
            return {
                ...state,
                getUsersAuth: false,
                Users: null,
                Admins:null,
                UsersList:null,
                AdminsList:null,
                delUserAuth: false,
                createUserAuth: false,
                getUserAuth: false,
                editUserAuth: false,
                getParentUsersAuth: false,
                updatedAuthUserStatus: false,
                APPROVED_USER:false,
                ParentUsersList: [],
                AllParentUsersList: [],
                getAllParentUsersAuth: true,
                getActiveUserAuth: false,

            }
        default:
            return {
                ...state
            }
    }
}