import {
    FORGOT_PASSWORD,
    GET_USER_VERIFY,
    RESET_PASSWORD,

} from '../../redux/types';

const initialState = {

    forgotPassword: null,
    forgotPasswordSuccess: false,
    forgotMsg: [],
    resetPasswordRes: [],
    forgotPasswordAuth: false,
    resetPasswordAuth: false,
    resetPasswordTokenAuth: false,
    userVerifyRes: {},
    userVerifyAuth: false,

}

export default function (state = initialState, action) {
    switch (action.type) {



        case FORGOT_PASSWORD:
            return {
                ...state,
                forgotPasswordSuccess: true,
                forgotPasswordAuth: true,
                forgotMsg: action.payload
            }
        case RESET_PASSWORD:
            return {
                ...state,
                resetPasswordAuth: true,
                resetPasswordRes: action.payload
            }
        case GET_USER_VERIFY:
            return {
                ...state,
                userVerifyRes: action.payload,
                userVerifyAuth: true
            }

        default:
            return {
                ...state
            }
    }
}