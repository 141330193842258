import React, { useState, useEffect, useRef } from 'react'
import { Images } from '../../assets/asset';
import { Dropdown, Button, Modal } from 'react-bootstrap';
import { ENV } from '../../config/config';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import './searchbar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCircle, faEye, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from "react-redux";
import { getNotification, beforeNotification, markAsReadAllNotifications, markAsReadNotification } from "../../views/Notification/Notification.action";
import {getUnreadMessageCount} from '../../views/Chat/Chat.action';
import { getActiveUser, beforeUser } from "../../views/User/User.action";

const SearchBar = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let userImage = localStorage.getItem('userImage');
    let urlImage = `${userImage}`;
    const ref = useRef(null);


    const [permissions, setPermissions] = useState(localStorage.getItem("permissions") ? JSON.parse(localStorage.getItem("permissions") || "[]") : '')

    /** STATES **/
    const [show, setShow] = useState(false);


    /**Show and hide notifications **/
    const handleShow = () => {
        setShow(!show);

    };

    const readAllNotification = ((e) => {
        e.preventDefault();
        dispatch(markAsReadAllNotifications());
    })

    useEffect(() => {
        dispatch(getActiveUser(localStorage.getItem('userID')))
        dispatch(beforeNotification());
        dispatch(getNotification());
        const qs = ENV.objectToQueryString({ receiver_id: localStorage.getItem('userID') })
        dispatch(getUnreadMessageCount(qs))

    }, [dispatch])


    useEffect(() => {
        const fetchNotifications = async () => {
            dispatch(getActiveUser(localStorage.getItem('userID')))
            dispatch(beforeNotification());
            dispatch(getNotification());
            const qs = ENV.objectToQueryString({ receiver_id: localStorage.getItem('userID') })
            dispatch(getUnreadMessageCount(qs))
        }

        const interval = setInterval(fetchNotifications, 10000);

        return () => {
            clearInterval(interval);
        };

    }, []);



    const markAsReadNotifications = useSelector((state) => state?.notification?.markAsReadAllNotificationAuth);
    useEffect(() => {
        if (markAsReadNotifications) {
            setShow(false);

        }
    }, [markAsReadNotifications])

    const active_user = useSelector((state) => state?.users?.activeUser?.data?.status);
    const permission_status = useSelector((state) => state?.users?.activeUser?.data?.permission_status);

    if(active_user === 0 || permission_status === 1){
        localStorage.removeItem("auth");
         localStorage.removeItem("permissions");
         localStorage.removeItem('userID');
         window.location.replace('/'); 
    }

    const notifications = useSelector((state) => state?.notification?.getNotification?.data);
    const total_count = useSelector((state) => state?.notification?.getNotification?.total_count);
    const unread_message_count = useSelector((state) => state?.chats?.getUnreadMessageCount?.count);

  
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                setShow(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
    }, []);

    const viewALlNotitifications = () => {
        setShow(false);
        navigate(`/notifications`)
    };


    const readNotification = ((Id) => {
        dispatch(markAsReadNotification(Id));
    })

    return (
        <div className='search-bar'>
            <div className='search-area'>
                <div className='searchbar'></div>

                {/* search icon */}
                {/* <button className='search-button'><img className='img-fluid searchIcon' src={Images.searchIcon} alt=" " /></button> */}

                {/* theme button */}
                {/* <button className='theme-button'><img className='img-fluid darkMode' src={Images.darkMode} alt=" " /></button> */}

                {/* notificaions */}
                {/* <Dropdown className='noti-dropdown'>
                <Dropdown.Toggle variant="success" id="notifictaions">
                    
                    <img className='img-fluid notification' src={Images.notification} alt=" " />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown> */}


                 {/* chat */}
                 {
                 permissions && permissions['View Messages'] &&
                 <Link to="/chat" >
                 <span className='noti-chat'>
                    <Button id="notifictaions">
                        <img className='img-fluid notification' src={Images.chat} alt=" " />
                        {unread_message_count && unread_message_count > 0 ?
                                <span className='blue-dot'>{unread_message_count}</span>
                                : ''
                        }
                    </Button>
                </span>
                </Link>
                 }



                {/* notificaions */}
                { permissions && permissions['View Notifications'] &&
                <span className='noti-dropdown' ref={ref}>
                    <Button id="notifictaions" onClick={handleShow} >
                        <img className='img-fluid notification' src={Images.notification} alt=" " />
                        {

                            total_count && total_count > 0 ?
                                <span className='blue-dot'>{total_count}</span>
                                : ""
                        }
                    </Button>

                    <div className={`noti-modal ${show ? "show" : ""}`}  >
                        {/* <span className='cross-icon' onClick={handleClose}><FontAwesomeIcon icon={faCancel} /></span> */}
                        <div className='title d-flex align-items-center justify-content-between'>
                            <span className='noti-tag'>Notifications</span>
                            {
                                total_count && total_count > 0 ?

                                    <span className='mark-link'><button  className='mark-button' onClick={readAllNotification}>Mark all as read</button></span>
                                    : ""
                            }
                        </div>
                        <div className='body'>
                            {
                                notifications && notifications.length ?
                                    notifications.map((i, index) =>

                                        <div className={`notifications ${i.is_read == 1 ? " " : "notification-active"}`} key={index}>
                                            
                                            <div className='noti-first'>
                                                <div className='noti-image'>
                                                    <img className="img-fluid" src={i.image} alt="" />
                                                </div>
                                                <div className={`noti-data ${i.is_read == 1 ? "" : "noti-data-active"}`}>
                                                    {/* <span className='noti-title'><span className='title-name'>{i.sender_name}</span> sends you a message</span> */}
                                                    <span className='noti-description'>{i.notification}</span>
                                                    {/* <span className='noti-description'>Sumra Riaz message is created successfully user erfqe erfqefq</span>                                          */}
                                                </div>
                                            </div>    

                                            <div className="noti-data-read">
                                                {i.is_read === 0 ?
                                                    <Link  onClick={() => readNotification(i.id)} className='noti-mark-as-read'>Mark as read</Link>
                                                    :
                                                    ''
                                                }
                                            </div>
                                            
                                        </div>
                                    ) : <div className="no-noti-tag text-center d-flex justify-content-center align-items-center">You don't have any new notifications .</div>
                            }
                        </div>
                        <div className='noti-footer'>
                            {
                                notifications && notifications.length ?
                                    <div className='all-noti-button'>
                                        <button className='' onClick={() => viewALlNotitifications()}>View All</button>
                                    </div>
                                    : ""
                            }
                        </div>
                    </div>
                </span>

                        }
                

                {/* profile pic */}
                <Dropdown className='profile-dropdown'>
                    <Dropdown.Toggle variant="success" id="profile-pic">
                        <img className='img-fluid profilePic' src={urlImage} alt=" " />
                        <span className='noti-name'>
                             {localStorage.getItem('currentUserName')}
                        </span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item href="/profile">Profile</Dropdown.Item>
                        <Dropdown.Item onClick={() => { localStorage.removeItem("auth"); localStorage.removeItem("permissions");  localStorage.removeItem('userID'); window.location.replace('/'); }}>Logout</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                
            </div>
        </div>
    )
}

export default SearchBar