// import { isArrowFunction } from 'typescript';
import {
    GET_ERRORS, GET_NOTIFICATION, GET_LISTING_NOTIFICATION,
    MARK_AS_READ_NOTIFICATION, MARK_AS_READ_ALL_NOTIFICATION, DELETE_NOTIFICATION, BEFORE_NOTIFICATION
} from './Types';

const initialState = {
    getNotificationsAuth: false,
    getListingNotificationsAuth: false,
    markAsReadNotificationAuth: false,
    markAsReadAllNotificationAuth: false,
    delNotificationAuth: false,
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_NOTIFICATION:
            return {
                ...state,
                getNotification: action.payload,
                getNotificationAuth: true
            }

        case GET_LISTING_NOTIFICATION:
            return {
                ...state,
                getNotifications: action.payload,
                getListingNotificationsAuth: true
            }
        case MARK_AS_READ_NOTIFICATION:
            return {
                ...state,
                notification: action.payload,
                markAsReadNotificationAuth: true
            }


        case MARK_AS_READ_ALL_NOTIFICATION:
            return {
                ...state,
                notification: action.payload,
                markAsReadAllNotificationAuth: true
            }


        
        case DELETE_NOTIFICATION:
            return {
                ...state,
                notification: action.payload,
                delNotificationAuth: true
            }
        case BEFORE_NOTIFICATION:
            return {
                ...state,
                getNotificationsAuth: false,
                getListingNotificationsAuth: false,
                markAsReadNotificationAuth: false,
                markAsReadAllNotificationAuth: false,
                delNotificationAuth: false,
            }
        default:
            return {
                ...state
            }
    }
}