// ERRORS
export const EMPTY_ERRORS = 'EMPTY_ERRORS';
export const GET_ERRORS = 'GET_ERRORS';

// STATES
export const GET_USERS = 'GET_USERS'
export const BEFORE_USER = 'BEFORE_USER'
export const DELETE_USER = 'DELETE_USER'
export const CREATE_USER = 'CREATE_USER'
export const GET_USER = 'GET_USER'
export const EDIT_USER = 'EDIT_USER'
export const GET_PARENT_USERS = 'GET_PARENT_USERS'
export const UPDATE_STATUS = 'UPDATE_STATUS'
export const APPROVED_USER = 'APPROVED_USER'
export const GET_ALL_PARENT_USERS = 'GET_ALL_PARENT_USERS'
export const GET_ADMINS = 'GET_ADMINS'
export const GET_ACTIVE_USER = 'GET_ACTIVE_USER'
