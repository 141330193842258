// import { isArrowFunction } from 'typescript';
import {GET_STATES, CREATE_STATE, GET_STATE, EDIT_STATE,BEFORE_STATE, DELETE_STATE} from './Types';

const initialState = {
    getStatesAuth: false,
    state: null,
    delStateAuth: false,
    createStateAuth: false,
    getStateAuth: false,
    editStateAuth: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_STATE:
            return {
                ...state,
                getState: action.payload,
                getStateAuth: true
            }
        case CREATE_STATE:
            return {
                ...state,
                createStateAuth: true
            }
        case GET_STATES:
            return {
                ...state,
                statesList: action.payload,
                getStatesAuth: true
            }
        case EDIT_STATE:
            return {
                ...state,
                State: action.payload,
                editStateAuth: true
            }
        case DELETE_STATE:
            return {
                ...state,
                State: action.payload,
                delStateAuth: true
            }
        case BEFORE_STATE:
            return {
                ...state,
                getStatesAuth: false,
                state: null,
                delStateAuth: false,
                createStateAuth: false,
                getStateAuth: false,
                editStateAuth: false
            }
        default:
            return {
                ...state
            }
    }
}