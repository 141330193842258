// import { isArrowFunction } from 'typescript';
import { GET_ERRORS, GET_SETTING, EDIT_SETTING, BEFORE_SETTING } from './Types';

const initialState = {
    getSettingsAuth: false,
    Settings: null,
    getSettingAuth: false,
    editSettingAuth: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_SETTING:
            return {
                ...state,
                getSetting: action.payload,
                getSettingAuth: true
            }

       
        case EDIT_SETTING:
            return {
                ...state,
                Setting: action.payload,
                editSettingAuth: true
            }

        case BEFORE_SETTING:
            return {
                ...state,
                getSettingsAuth: false,
                Settings: null,
                getSettingAuth: false,
                editSettingAuth: false
            }
        default:
            return {
                ...state
            }
    }
}