// ERRORS
export const EMPTY_ERRORS = 'EMPTY_ERRORS';
export const GET_ERRORS = 'GET_ERRORS';

// USER EMAIL TEMPLATE
export const GET_USER_EMAIL_TEMPLATES = 'GET_USER_EMAIL_TEMPLATES'
export const BEFORE_USER_EMAIL_TEMPLATE = 'BEFORE_USER_EMAIL_TEMPLATE'
export const DELETE_USER_EMAIL_TEMPLATE = 'DELETE_USER_EMAIL_TEMPLATE'
export const CREATE_USER_EMAIL_TEMPLATE = 'CREATE_USER_EMAIL_TEMPLATE'
export const GET_USER_EMAIL_TEMPLATE = 'GET_USER_EMAIL_TEMPLATE'
export const EDIT_USER_EMAIL_TEMPLATE = 'EDIT_USER_EMAIL_TEMPLATE'
export const GET_USER_EMAIL_TYPES = 'GET_USER_EMAIL_TYPES'
