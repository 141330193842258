import { toast } from 'react-toastify';
import {DELETE_MESSAGE_LIST, GET_UNREAD_MESSAGES_COUNT, DELETE_MESSAGE,SEARCH_USER_NOT_FOUND,GET_MESSAGES_THREAD, GET_MESSAGE_THREAD,GET_SEARCH_USER, BEFORE_MESSAGE_THREAD, CREATE_MESSAGE_THREAD, GET_ERRORS } from './Types';
import { emptyError } from '../../redux/shared/error/error.action';
import { ENV } from '../../config/config';
import { apiHelper } from "../../redux/apiHelper"


export const beforeMessageThread = () => {
    return {
        type: BEFORE_MESSAGE_THREAD
    }
}

export const getMessagesThread = (qs = '', body ={}) => dispatch => {
    dispatch(emptyError());
    let url = `${ENV.url}get-message-threads`;
    if (qs)
        url += `?${qs}`

    fetch(url, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'x-auth-user': ENV.x_auth_user
        },
        // body: JSON.stringify(body)
    }).then(res => res.json()).then(data => {
        if (data.success) {
            dispatch({
                type: GET_MESSAGES_THREAD,
                payload: data.data
            })
        }
    }).catch(error => {
        if (error.response && error.response.data) {
            const { data } = error.response
            if (data.message)
                toast.error(data.message)
        }
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
};


export const searchUsers = (qs = '', body ={}) => dispatch => {
    dispatch(emptyError());
    let url = `${ENV.url}get-users`;
    if (qs)
        url += `?${qs}`

    fetch(url, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'x-auth-user': ENV.x_auth_user
        },
        // body: JSON.stringify(body)
    }).then(res => res.json()).then(data => {
        if (data.success) {
            dispatch({
                type: GET_SEARCH_USER,
                payload: data.data
            })
        }else{
            dispatch({
                type: SEARCH_USER_NOT_FOUND,
                payload: data
            })
        }
    }).catch(error => {
        if (error.response && error.response.data) {
            const { data } = error.response
            if (data.message)
                toast.error(data.message)
        }
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
};



export const fetchMessage = (qs = '', body ={}) => dispatch => {
    dispatch(emptyError());
    let url = `${ENV.url}get-user-messages`;
    if (qs)
        url += `?${qs}`

    fetch(url, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'x-auth-user': ENV.x_auth_user
        },
        // body: JSON.stringify(body)
    }).then(res => res.json()).then(data => {
        if (data.success) {
            dispatch({
                type: GET_MESSAGE_THREAD,
                payload: data
            })
        }
    }).catch(error => {
        if (error.response && error.response.data) {
            const { data } = error.response
            if (data.message)
                toast.error(data.message)
        }
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
};


export const sendMessage = (body) => dispatch => {
    dispatch(emptyError());
    const url = `${ENV.url}send-message`;

    fetch(url, {
        method: 'POST',
        headers: {
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'x-auth-user': ENV.x_auth_user
        },
        body
    }).then(res => res.json()).then(data => {
        if (data.success) {
            // toast.success(data.message)
            dispatch({
                type: CREATE_MESSAGE_THREAD,
                payload: data
            })
        } else {
            toast.error(data.message)
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(error => {
        if (error.response && error.response.data) {
            const { data } = error.response
            if (data.message)
                toast.error(data.message)
        }
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
};


export const deleteMessage = (qs = '', body ={}) => dispatch => {
    dispatch(emptyError());
    let url = `${ENV.url}delete-user-messages`;
    if (qs)
        url += `?${qs}`

    fetch(url, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'x-auth-user': ENV.x_auth_user
        },
        // body: JSON.stringify(body)
    }).then(res => res.json()).then(data => {
        if (data.success) {
            dispatch({
                type: DELETE_MESSAGE,
                payload: data
            })
        }
    }).catch(error => {
        if (error.response && error.response.data) {
            const { data } = error.response
            if (data.message)
                toast.error(data.message)
        }
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
};

export const getUnreadMessageCount = (qs = '', body ={}) => dispatch => {
    dispatch(emptyError());
    let url = `${ENV.url}get-unread-message-count`;
    if (qs)
        url += `?${qs}`

    fetch(url, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'x-auth-user': ENV.x_auth_user
        },
        // body: JSON.stringify(body)
    }).then(res => res.json()).then(data => {
        if (data.success) {
            dispatch({
                type: GET_UNREAD_MESSAGES_COUNT,
                payload: data.data
            })
        }
    }).catch(error => {
        if (error.response && error.response.data) {
            const { data } = error.response
            if (data.message)
                toast.error(data.message)
        }
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
};

export const deleteMessageList = (qs = '', body ={}) => dispatch => {
    dispatch(emptyError());
    let url = `${ENV.url}delete-message-list`;
    if (qs)
        url += `?${qs}`

    fetch(url, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'x-auth-user': ENV.x_auth_user
        }
    }).then(res => res.json()).then(data => {
        console.log("return", data)
        if (data.success) {
            toast.success(data.message)
            dispatch({
                type: DELETE_MESSAGE_LIST,
                payload: data
            })
        } else {
            toast.error(data.data.error)
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(error => {
        if (error.response && error.response.data) {
            const { data } = error.response
            if (data.message)
                toast.error(data.message)
        }
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
};
