// import { isArrowFunction } from 'typescript';
import { BEFORE_COUNTRY, DELETE_COUNTRY, GET_COUNTRY, EDIT_COUNTRY,GET_COUNTRIES, CREATE_COUNTRY } from './Types';

const initialState = {
    getCountriesAuth: false,
    country: null,
    delCountryAuth: false,
    createCountryAuth: false,
    getCountryAuth: false,
    editCountryAuth: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_COUNTRY:
            return {
                ...state,
                getCountry: action.payload,
                getCountryAuth: true
            }
        case CREATE_COUNTRY:
            return {
                ...state,
                createCountryAuth: true
            }
        case GET_COUNTRIES:
            return {
                ...state,
                countriesList: action.payload,
                getCountriesAuth: true
            }
        case EDIT_COUNTRY:
            return {
                ...state,
                country: action.payload,
                editCountryAuth: true
            }
        case DELETE_COUNTRY:
            return {
                ...state,
                country: action.payload,
                delCountryAuth: true
            }
        case BEFORE_COUNTRY:
            return {
                ...state,
                getCountriesAuth: false,
                country: null,
                delCountryAuth: false,
                createCountryAuth: false,
                getCountryAuth: false,
                editCountryAuth: false
            }
        default:
            return {
                ...state
            }
    }
}