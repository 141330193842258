// ERRORS
export const EMPTY_ERRORS = 'EMPTY_ERRORS';
export const GET_ERRORS = 'GET_ERRORS';

// STATES
export const GET_CITIES = 'GET_CITIES'
export const BEFORE_CITY = 'BEFORE_CITY'
export const DELETE_CITY = 'DELETE_CITY'
export const CREATE_CITY = 'CREATE_CITY'
export const GET_CITY = 'GET_CITY'
export const EDIT_CITY = 'EDIT_CITY'
