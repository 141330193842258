// ERRORS
export const EMPTY_ERRORS = 'EMPTY_ERRORS';
export const GET_ERRORS = 'GET_ERRORS';

// ADMIN
export const ADD_ADMIN = 'ADD_ADMIN';
export const BEFORE_ADMIN = 'BEFORE_ADMIN';
export const LOGIN_ADMIN = 'LOGIN_ADMIN';
export const GET_ADMIN = 'GET_ADMIN';
export const GET_ADMINS = 'GET_ADMINS';
export const UPDATE_ADMIN = 'UPDATE_ADMIN';
export const DELETE_ADMIN = 'DELETE_ADMIN';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const GET_USER_VERIFY = 'GET_USER_VERIFY';
export const BEFORE_USER_VERIFY = 'BEFORE_USER_VERIFY';
export const SET_PASSWORD = 'SET_PASSWORD';
export const VERIFY_EMAIL = 'VERIFY_EMAIL';
export const VERIFY_EMAIL_SENT = 'VERIFY_EMAIL_SENT';

// ROLES
export const ADD_ROLE = 'ADD_ROLE';
export const UPDATE_ROLE = 'UPDATE_ROLE';
export const DELETE_ROLE = 'DELETE_ROLE';
export const GET_ROLE = 'GET_ROLE';
export const EXISTS_ROLE = 'EXISTS_ROLE';
export const GET_ROLES = 'GET_ROLES';
export const BEFORE_ROLE = 'BEFORE_ROLE';
export const BEFORE_PERMISSION = 'BEFORE_PERMISSION';
export const GET_PERMISSION = 'GET_PERMISSION';
export const SET_LOADER_ROLE = 'SET_LOADER_ROLE';
export const REMOVE_LOADER_ROLE = 'REMOVE_LOADER_ROLE';

// USERS
export const BEFORE_CUSTOMER = 'BEFORE_CUSTOMER';
export const GET_CUSTOMERS = 'GET_CUSTOMERS';
export const GET_CUSTOMER = 'GET_CUSTOMER';
export const UPSERT_CUSTOMER = 'UPSERT_CUSTOMER';
export const DELETE_CUSTOMER = 'DELETE_CUSTOMER';
export const UPSERT_ADDRESS = 'UPSERT_ADDRESS';
export const CUSTOMER_VERIFICATION = 'CUSTOMER_VERIFICATION';
export const UPSERT_EXTRACT = 'UPSERT_EXTRACT';

// Emails
export const GET_EMAILS = 'GET_EMAILS';
export const GET_EMAIL = 'GET_EMAIL';
export const BEFORE_EMAIL = 'BEFORE_EMAIL';
export const UPDATE_EMAIL = 'UPDATE_EMAIL';

// Settings
export const GET_SETTINGS = 'GET_SETTINGS';
export const EDIT_SETTINGS = 'EDIT_SETTINGS';
export const BEFORE_SETTINGS = 'BEFORE_SETTINGS';

// COUNTRY
export const GET_COUNTRIES = 'GET_COUNTRIES'
export const BEFORE_COUNTRY = 'BEFORE_COUNTRY'
export const DELETE_COUNTRY = 'DELETE_COUNTRY'
export const CREATE_COUNTRY = 'CREATE_COUNTRY'
export const GET_COUNTRY = 'GET_COUNTRY'
export const EDIT_COUNTRY = 'EDIT_COUNTRY'

// STATES
export const GET_STATES = 'GET_STATES'
export const BEFORE_STATE = 'BEFORE_STATE'
export const DELETE_STATE = 'DELETE_STATE'
export const CREATE_STATE = 'CREATE_STATE'
export const GET_STATE = 'GET_STATE'
export const EDIT_STATE = 'EDIT_STATE'

// USER EMAIL TEMPLATE
export const GET_USER_EMAIL_TEMPLATES = 'GET_USER_EMAIL_TEMPLATES'
export const BEFORE_USER_EMAIL_TEMPLATE = 'BEFORE_USER_EMAIL_TEMPLATE'
export const DELETE_USER_EMAIL_TEMPLATE = 'DELETE_USER_EMAIL_TEMPLATE'
export const CREATE_USER_EMAIL_TEMPLATE = 'CREATE_USER_EMAIL_TEMPLATE'
export const GET_USER_EMAIL_TEMPLATE = 'GET_USER_EMAIL_TEMPLATE'
export const EDIT_USER_EMAIL_TEMPLATE = 'EDIT_USER_EMAIL_TEMPLATE'
export const GET_USER_EMAIL_TYPES = 'GET_USER_EMAIL_TYPES'

// USER EMAIL TYPES
export const GET_EMAIL_TYPES = 'GET_EMAIL_TYPES'
export const BEFORE_EMAIL_TYPE = 'BEFORE_EMAIL_TYPE'
export const DELETE_EMAIL_TYPE = 'DELETE_EMAIL_TYPE'
export const CREATE_EMAIL_TYPE = 'CREATE_EMAIL_TYPE'
export const GET_EMAIL_TYPE = 'GET_EMAIL_TYPE'
export const EDIT_EMAIL_TYPE = 'EDIT_EMAIL_TYPE'

// DASHBOARD
export const BEFORE_DASHBOARD = 'BEFORE_DASHBOARD'
export const GET_DASHBOARD = 'GET_DASHBOARD'

//CONTACT US
export const GET_CONTACTUS = 'GET_CONTACTUS'
export const BEFORE_CONTACTUS = 'BEFORE_CONTACTUS'
export const DELETE_CONTACTUS = 'DELETE_CONTACTUS'
export const CREATE_CONTACTUS = 'CREATE_CONTACTUS'

