// import { isArrowFunction } from 'typescript';
import { GET_CITIES, CREATE_CITY, GET_CITY, EDIT_CITY,BEFORE_CITY, DELETE_CITY} from './Types';

const initialState = {
    getCitiesAuth: false,
    cities: null,
    delCityAuth: false,
    createCityAuth: false,
    getCityAuth: false,
    editCityAuth: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_CITY:
            return {
                ...state,
                getCity: action.payload,
                getCityAuth: true
            }
        case CREATE_CITY:
            return {
                ...state,
                createCityAuth: true
            }
        case GET_CITIES:
            return {
                ...state,
                CitiesList: action.payload,
                getCitiesAuth: true
            }
        case EDIT_CITY:
            return {
                ...state,
                City: action.payload,
                editCityAuth: true
            }
        case DELETE_CITY:
            return {
                ...state,
                City: action.payload,
                delCityAuth: true
            }
        case BEFORE_CITY:
            return {
                ...state,
                getCitiesAuth: false,
                cities: null,
                delCityAuth: false,
                createCityAuth: false,
                getCityAuth: false,
                editCityAuth: false
            }
        default:
            return {
                ...state
            }
    }
}