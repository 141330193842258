// import { isArrowFunction } from 'typescript';
import { CREATE_CONTACTUS, GET_CONTACTUS, DELETE_CONTACTUS, BEFORE_CONTACTUS } from './Types';

const initialState = {
    getContactusAuth: false,
    Contactus: null,
    getContactusAuth: false,
    editContactusAuth: false,
    createContactusAuth: false,
    delContactusAuth: false,
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_CONTACTUS:
            return {
                ...state,
                getContactus: action.payload,
                getContactusAuth: true
            }

        case CREATE_CONTACTUS:
            return {
                ...state,
                createContactusAuth: true
            }

        // case EDIT_CONTACTUS:
        //     return {
        //         ...state,
        //         Contatcus: action.payload,
        //         editContactusAuth: true
        //     }
        case DELETE_CONTACTUS:
            return {
                ...state,
                Contatcus: action.payload,
                delContactusAuth: true
            }
        case BEFORE_CONTACTUS:
            return {
                ...state,
                getContactusAuth: false,
                Contactus: null,
                getContactusAuth: false,
                editContactusAuth: false,
                createContactusAuth: false,
                delContactusAuth:false
            }
        default:
            return {
                ...state
            }
    }
}