import errorReducer from './shared/error/error.reducer'
import CountryReducer from '../views/Country/Country.reducer'
import StateReducer from '../views/State/State.reducer';
import StoreReducer from '../views/Store/Store.reducer';
import CityReducer from '../views/City/City.reducer';
import registerReducer from '../views/Register/register.reducer';
import passwordReducer from '../views/Password/password.reducer';
import RoleReducer from '../views/RolePermission/Role.reducer';
import UserReducer from '../views/User/User.reducer';
import SettingReducer from '../views/Setting/Setting.reducer';
import ContactusReducer from '../views/Contactus/Contactus.reducer';
import FaqReducer from '../views/Faq/Faq.reducer';
import EmailTemplateReducer from '../views/EmailTemplate/EmailTemplate.reducer';
import ChatReducer from '../views/Chat/Chat.reducer';
import NotificationReducer from '../views/Notification/Notification.reducer';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from '@redux-devtools/extension';
import thunk from 'redux-thunk'

const middleware = [thunk]

const reducer = combineReducers({
  states: StateReducer,
  countries: CountryReducer,
  stores : StoreReducer,
  cities : CityReducer,
  register : registerReducer, 
  password : passwordReducer, 
  roles:RoleReducer,
  setting:SettingReducer,
  users:UserReducer,
  faqs : FaqReducer,
  chats : ChatReducer,
  error: errorReducer,
  contactus: ContactusReducer,
  emailtemplate : EmailTemplateReducer,
  notification : NotificationReducer
});

const store = createStore(
  reducer,
  composeWithDevTools(applyMiddleware(...middleware))
)

export default store
