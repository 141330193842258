// ERRORS
export const EMPTY_ERRORS = 'EMPTY_ERRORS';
export const GET_ERRORS = 'GET_ERRORS';

// STATES
export const GET_STATES = 'GET_STATES'
export const BEFORE_STATE = 'BEFORE_STATE'
export const DELETE_STATE = 'DELETE_STATE'
export const CREATE_STATE = 'CREATE_STATE'
export const GET_STATE = 'GET_STATE'
export const EDIT_STATE = 'EDIT_STATE'
