// ERRORS
export const EMPTY_ERRORS = 'EMPTY_ERRORS';
export const GET_ERRORS = 'GET_ERRORS';

// COUNTRY
export const GET_MESSAGES_THREAD = 'GET_MESSAGES_THREAD'
export const BEFORE_MESSAGE_THREAD = 'BEFORE_MESSAGE_THREAD'
export const CREATE_MESSAGE_THREAD = 'CREATE_MESSAGE_THREAD'
export const GET_MESSAGE_THREAD = 'GET_MESSAGE_THREAD'
export const GET_SEARCH_USER = 'GET_SEARCH_USER'
export const SEARCH_USER_NOT_FOUND = 'SEARCH_USER_NOT_FOUND'
export const DELETE_MESSAGE = 'DELETE_MESSAGE'
export const GET_UNREAD_MESSAGES_COUNT = "GET_UNREAD_MESSAGES_COUNT"
export const DELETE_MESSAGE_LIST = "DELETE_MESSAGE_LIST"
