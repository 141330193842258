// import { isArrowFunction } from 'typescript';
import {DELETE_MESSAGE_LIST, GET_UNREAD_MESSAGES_COUNT, SEARCH_USER_NOT_FOUND, GET_MESSAGES_THREAD, GET_SEARCH_USER, GET_MESSAGE_THREAD, BEFORE_MESSAGE_THREAD, CREATE_MESSAGE_THREAD, DELETE_MESSAGE } from './Types';

const initialState = {
    getMessagesThreadAuth: false,
    messageThread: null,
    createMessageThreadAuth: false,
    getMessageThreadAuth: false,
    getSearchUserAuth:false,
    searchUserNotFoundAuth:false,
    deleteMessageAuth:false,
    deleteMessageListAuth:false,
    getUnreadMessageCountAuth:false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_MESSAGE_THREAD:
            return {
                ...state,
                getMessageThread: action.payload,
                getMessageThreadAuth: true
            }
            case DELETE_MESSAGE:
            return {
                ...state,
                getMessageThread: action.payload,
                deleteMessageAuth: true
            }
            case DELETE_MESSAGE_LIST:
                return {
                    ...state,
                    getMessageThread: action.payload,
                    deleteMessageListAuth: true
                }
            case GET_UNREAD_MESSAGES_COUNT:
                return {
                    ...state,
                    getUnreadMessageCount: action.payload,
                    getUnreadMessageCountAuth: true
                }
            case GET_SEARCH_USER:
            return {
                ...state,
                searchUser: action.payload,
                getSearchUserAuth: true
            }
            case SEARCH_USER_NOT_FOUND:
                return {
                    ...state,
                    searchUser: action.payload,
                    searchUserNotFoundAuth: true
                }
        case CREATE_MESSAGE_THREAD:
            return {
                ...state,
                createMessageThreadAuth: true
            }
        case GET_MESSAGES_THREAD:
            return {
                ...state,
                messageThreadList: action.payload,
                getMessagesThreadAuth: true
            }
        case BEFORE_MESSAGE_THREAD:
            return {
                ...state,
                getMessagesThreadAuth: false,
                messageThread: null,
                createMessagesThreadAuth: false,
                getMessageThreadAuth: false,
                getSearchUserAuth:false,
                searchUserNotFoundAuth:false,
                deleteMessageAuth:false,
                getUnreadMessageCountAuth:false,
                deleteMessageListAuth:false,
            }
        default:
            return {
                ...state
            }
    }
}
