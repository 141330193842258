import { toast } from 'react-toastify';
import {
    GET_ERRORS, GET_NOTIFICATION, GET_LISTING_NOTIFICATION,
    MARK_AS_READ_NOTIFICATION, MARK_AS_READ_ALL_NOTIFICATION, DELETE_NOTIFICATION, BEFORE_NOTIFICATION
} from './Types';
import { emptyError } from '../../redux/shared/error/error.action';
import { ENV } from '../../config/config';

export const beforeNotification = () => {
    return {
        type: BEFORE_NOTIFICATION
    }
}


export const getNotification = () => dispatch => {


    dispatch(emptyError());
    let url = `${ENV.url}get-all-notifications`;

    fetch(url, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'Authorization': `Bearer ` + localStorage.getItem('auth'),
            'x-auth-token': ENV.x_auth_token,
            'x-auth-user': ENV.x_auth_user
        }
    }).then(res => res.json()).then(data => {

        if (data) {

            dispatch({
                type: GET_NOTIFICATION,
                payload: data
            })
        } else {
            toast.error(data.message)
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(error => {
        if (error.response && error.response.data) {
            const { data } = error.response
            if (data.message)
                toast.error(data.message)
        }
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
};

export const getListingNotification = (qs = '', body = {}) => dispatch => {


    dispatch(emptyError());
    let url = `${ENV.url}get-listing-notifications`;
    if (qs)
        url += `?${qs}`

    fetch(url, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'Authorization': `Bearer ` + localStorage.getItem('auth'),
            'x-auth-token': ENV.x_auth_token,
            'x-auth-user': ENV.x_auth_user
        }
    }).then(res => res.json()).then(data => {

        if (data) {

            dispatch({
                type: GET_LISTING_NOTIFICATION,
                payload: data
            })
        } else {
            toast.error(data.message)
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(error => {
        if (error.response && error.response.data) {
            const { data } = error.response
            if (data.message)
                toast.error(data.message)
        }
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
};

export const markAsReadAllNotifications = (body) => dispatch => {

    dispatch(emptyError());
    const url = `${ENV.url}mark-as-read-all-notifications`;

    fetch(url, {
        method: 'POST',
        headers: {
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'x-auth-user': ENV.x_auth_user
        },
        body
    }).then(res => res.json()).then(data => {
        if (data.success) {
            toast.success(data.message)
            dispatch({
                type: MARK_AS_READ_ALL_NOTIFICATION,
                payload: data
            })
        } else {
            toast.error(data.message)
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(error => {
        if (error.response && error.response.data) {
            const { data } = error.response
            if (data.message)
                toast.error(data.message)
        }
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
};
export const markAsReadNotification = (id) => dispatch => {

    dispatch(emptyError());
    const url = `${ENV.url}mark-as-read-notification?id=${id}`;

    fetch(url, {
        method: 'POST',
        headers: {
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'x-auth-user': ENV.x_auth_user
        },
        
    }).then(res => res.json()).then(data => {
        if (data.success) {
            toast.success(data.message)
            dispatch({
                type: MARK_AS_READ_NOTIFICATION,
                payload: data
            })
        } else {
            toast.error(data.message)
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(error => {
        if (error.response && error.response.data) {
            const { data } = error.response
            if (data.message)
                toast.error(data.message)
        }
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
};

export const deleteNotification = (id) => dispatch => {
    dispatch(emptyError());
    let url = `${ENV.url}delete-notifications?id=${id}`;

    fetch(url, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'x-auth-user': ENV.x_auth_user
        }
    }).then(res => res.json()).then(data => {
        if (data.success) {
            toast.success(data.message)
            dispatch({
                type: DELETE_NOTIFICATION,
                payload: data
            })
        } else {
            toast.error(data.message)
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(error => {
        if (error.response && error.response.data) {
            const { data } = error.response
            if (data.message)
                toast.error(data.message)
        }
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
};