// ERRORS
export const EMPTY_ERRORS = 'EMPTY_ERRORS';
export const GET_ERRORS = 'GET_ERRORS';

// Faq
export const GET_FAQS = 'GET_FAQS'
export const BEFORE_FAQ = 'BEFORE_FAQ'
export const DELETE_FAQ = 'DELETE_FAQ'
export const CREATE_FAQ = 'CREATE_FAQ'
export const GET_FAQ = 'GET_FAQ'
export const EDIT_FAQ = 'EDIT_FAQ'
